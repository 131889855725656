import steering from './steering-wheel.svg';
import './App.css';

function App() {
    return (<div className="App">
        <header className="App-header">
            <img src={steering} className="App-logo" alt="logo"/>
            <p className={"pulsate"}>
                Coming soon...
            </p>
        </header>

        <footer>&copy; <a href="https://www.certificateclass.com">certificateclass.com</a> and <a href="https://www.alldmvtests.com">alldmvtests.com</a></footer>
    </div>);
}

export default App;
